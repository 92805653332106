import React from 'react'
import { Link } from 'gatsby'
import { getFormattedDate } from 'util/date'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getDiskDuration } from 'util/time'
// Components
import SocialIcons from 'components/UIElements/SocialIcons'
// Models
import { IDisk } from 'models/Disk'
// Styles
import * as styles from './DiskCard.module.css'

type DiskCardProps = {
    disk: IDisk
}

const DiskCard: React.FC<DiskCardProps> = ({ disk }) => {
    const { name, slug, cover, songs } = disk
    const { youtube, youtubeMusic, spotify, amazonMusic, deezer } = disk
    const release_date =
        disk.release_date && getFormattedDate(disk.release_date)
    const duration = songs && getDiskDuration(songs)
    return (
        <article className={styles.disk_card}>
            <Link to={`/discography/${disk.slug}`}>
                <GatsbyImage
                    className={styles.disk_cover}
                    image={cover.gatsbyImageData}
                    alt={name}
                />
            </Link>
            <div className={styles.disk_info}>
                <Link
                    to={`/discography/${disk.slug}`}
                    className={styles.disk_title}
                >
                    {name}
                </Link>
                <Link
                    to={`/discography/${disk.slug}`}
                    className={styles.disk_data}
                >
                    <p>
                        <FontAwesomeIcon
                            className={styles.disk_icons}
                            icon={['far', 'calendar-alt']}
                        />{' '}
                        {release_date || 'Desconocido'}
                    </p>
                    <p>
                        <FontAwesomeIcon
                            className={styles.disk_icons}
                            icon={['fas', 'music']}
                        />{' '}
                        {songs
                            ? `${
                                  songs.length > 1
                                      ? `${songs.length} canciones`
                                      : `${songs.length} canción`
                              }`
                            : 'Desconocido'}
                    </p>
                    <p>
                        <FontAwesomeIcon
                            className={styles.disk_icons}
                            icon={['far', 'clock']}
                        />

                        {duration ? duration : 'Desconocido'}
                    </p>
                </Link>
                <SocialIcons
                    className={styles.disk_streaming}
                    youtube={youtube}
                    youtubeMusic={youtubeMusic}
                    spotify={spotify}
                    deezer={deezer}
                    amazonMusic={amazonMusic}
                />
            </div>
        </article>
    )
}

export default DiskCard
