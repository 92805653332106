import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import SectionHeader from 'components/Layout/SectionHeader'
import DiscographyContainer from 'components/Sections/Discography/DiscographyContainer'
// Models
import { IOptionalMetaProps, ISeoQuery } from 'models/SEO'
import { IContextWithPageNav } from 'models/Context'
import { IDisk } from 'models/Disk'

type DiscographyPageProps = {
    discography: {
        nodes: IDisk[]
    }
    pageSeo: ISeoQuery
}

const DiscographyPage: React.FC<
    PageProps<DiscographyPageProps, IContextWithPageNav>
> = ({ data, pageContext: context }) => {
    const discography = data.discography.nodes
    const { pageSeo } = data
    const seo: IOptionalMetaProps = {
        title: pageSeo.title,
        description: pageSeo.description.text,
        image: pageSeo.image.file?.url,
    }
    return (
        <Layout seo={seo}>
            <SectionHeader title={pageSeo.title} image={pageSeo.image} />
            <DiscographyContainer discography={discography} context={context} />
        </Layout>
    )
}

export default DiscographyPage

export const pageQuery = graphql`
    query DiscographyPage($limit: Int!, $skip: Int!) {
        discography: allContentfulDisks(
            limit: $limit
            skip: $skip
            sort: { fields: release_date, order: DESC }
        ) {
            nodes {
                name
                slug
                hachi
                type
                release_date
                cover {
                    gatsbyImageData(placeholder: BLURRED, width: 300)
                }

                amazonMusic
                deezer
                spotify
                youtube
                youtubeMusic

                songs {
                    name
                    slug
                    duration
                }

                authors {
                    name
                    slug
                }
            }
        }

        pageSeo: contentfulSeo(slug: { eq: "discography" }) {
            title
            description {
                text: description
            }
            image {
                gatsbyImageData
                file {
                    url
                }
            }
        }
    }
`
