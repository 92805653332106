import React from 'react'
// Components
import Content from 'components/Layout/Content'
import DefaultSidebar from 'components/Layout/Sidebar/DefaultSidebar'
import DisksList from './DisksList'
import PageNav from 'components/UIElements/Navigation/PageNav'
// Models
import { IContextWithPageNav } from 'models/Context'
import { IDisk } from 'models/Disk'
// Styles
import * as styles from './DiscographyContainer.module.css'

type DiscographyContainerProps = {
    discography: IDisk[]
    context: IContextWithPageNav
}

const DiscographyContainer: React.FC<DiscographyContainerProps> = ({
    discography,
    context,
}) => {
    const { currentPage, totalPages } = context
    return (
        <section className={styles.discography}>
            <Content>
                <DisksList discography={discography} />
                <PageNav
                    to="/discography"
                    currentPage={currentPage}
                    totalPages={totalPages}
                />
            </Content>
            <DefaultSidebar />
        </section>
    )
}

export default DiscographyContainer
