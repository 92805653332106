import React from 'react'
// Components
import DiskCard from './DiskCard'
// Models
import { IDisk } from 'models/Disk'

type DiskListProps = {
    discography: IDisk[]
}

const DisksList: React.FC<DiskListProps> = ({ discography }) => {
    return (
        <>
            {discography.map((disk) => (
                <DiskCard key={disk.slug} disk={disk} />
            ))}
        </>
    )
}

export default DisksList
